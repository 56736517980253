<template>
    <div class="wrap">
        <h1>ไม่พบหน้าที่คุณค้นหา</h1>
    </div>
</template>

<script>
    export default {
        name : "NotFound"
    }
</script>

<style scoped>
.wrap {
    color: #7c7a7a;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
